
/*
 =========================================================
 * Black Dashboard Angular - v1.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard-angular
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

 // Core

@import "black-dashboard/custom/functions";
@import "black-dashboard/bootstrap/functions";

@import "black-dashboard/custom/variables";
@import "black-dashboard/bootstrap/variables";

@import "black-dashboard/custom/mixins";
@import "black-dashboard/bootstrap/mixins";

 // Bootstrap components

@import "black-dashboard/bootstrap/root";
@import "black-dashboard/bootstrap/reboot";
@import "black-dashboard/bootstrap/type";
@import "black-dashboard/bootstrap/images";
@import "black-dashboard/bootstrap/code";
@import "black-dashboard/bootstrap/grid";
@import "black-dashboard/bootstrap/tables";
@import "black-dashboard/bootstrap/forms";
@import "black-dashboard/bootstrap/buttons";
@import "black-dashboard/bootstrap/transitions";
@import "black-dashboard/bootstrap/dropdown";
@import "black-dashboard/bootstrap/button-group";
@import "black-dashboard/bootstrap/input-group";
@import "black-dashboard/bootstrap/custom-forms";
@import "black-dashboard/bootstrap/nav";
@import "black-dashboard/bootstrap/navbar";
@import "black-dashboard/bootstrap/card";
@import "black-dashboard/bootstrap/breadcrumb";
@import "black-dashboard/bootstrap/pagination";
@import "black-dashboard/bootstrap/badge";
@import "black-dashboard/bootstrap/jumbotron";
@import "black-dashboard/bootstrap/alert";
@import "black-dashboard/bootstrap/progress";
@import "black-dashboard/bootstrap/media";
@import "black-dashboard/bootstrap/list-group";
@import "black-dashboard/bootstrap/close";
@import "black-dashboard/bootstrap/modal";
@import "black-dashboard/bootstrap/tooltip";
@import "black-dashboard/bootstrap/popover";
@import "black-dashboard/bootstrap/carousel";
@import "black-dashboard/bootstrap/utilities";
@import "black-dashboard/bootstrap/print";

 // Custom components

 @import "black-dashboard/custom/alerts.scss";
 @import "black-dashboard/custom/buttons.scss";
 @import "black-dashboard/custom/dropdown.scss";
 @import "black-dashboard/custom/footer.scss";
 @import "black-dashboard/custom/forms.scss";
 @import "black-dashboard/custom/images.scss";
 @import "black-dashboard/custom/modal.scss";
 @import "black-dashboard/custom/navbar.scss";
 @import "black-dashboard/custom/type.scss";
 @import "black-dashboard/custom/tables";
 @import "black-dashboard/custom/checkboxes-radio";
 @import "black-dashboard/custom/fixed-plugin";
 @import "black-dashboard/custom/sidebar-and-main-panel.scss";
 @import "black-dashboard/custom/misc.scss";
 @import "black-dashboard/custom/rtl.scss";
 @import "black-dashboard/custom/input-group.scss";


 // Vendor / Plugins

@import "black-dashboard/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard/custom/vendor/plugin-animate-bootstrap-notify.scss";

// light mode

@import "black-dashboard/custom/white-content.scss";

// Cards

@import 'black-dashboard/custom/card';
@import "black-dashboard/custom/cards/card-chart";
@import "black-dashboard/custom/cards/card-map";
@import "black-dashboard/custom/cards/card-user";
@import "black-dashboard/custom/cards/card-task";
@import "black-dashboard/custom/cards/card-plain";

// Angular Differences

@import "black-dashboard/angular-differences/angular-differences"
